<template>
  <div class="about">
    <slideshow :slideshowList="slideshowList"></slideshow>
    <img class="debris" src="~static/debris.png" />
    <img class="Aboutbg" style="top: 17%; right: 0" src="~static/crane.png" />
    <img class="Aboutbg" style="top: 60%; right: 0" src="~static/crane.png" />
    <img class="Aboutbg" style="top: 33%; left: 0" src="~static/wasteL.png" />
    <img class="Aboutbg" style="top: 56%; left: 0" src="~static/wasteL.png" />
    <img class="Aboutbg" style="bottom: 0; left: 0" src="~static/wasteL.png" />
    <img class="Aboutbg" style="top: 34%; right: 0" src="~static/wasteR.png" />
    <img
      class="Aboutbg"
      style="bottom: 13%; right: 0"
      src="~static/wasteR.png"
    />
    <el-container>
      <el-main>
        <div>
          <div id="general" class="general" style="padding: 120px 170px 0">
            <div style="width: 100%">
              <!-- <hr /> -->
              <div class="hr-more">
                <div>Introduction</div>
              </div>

              <div class="scenic">
                <div style="position: relative">
                  <span class="video-box"></span>
                  <video
                    style="object-fit: fill; margin-top: -81px"
                    width="550"
                    height="500"
                    poster="~static/videoFm.png"
                    controls="controls"
                    src="http://117.80.146.163:18080/tznmh/tzn_img/tiaozini.mp4"
                    type="video/mp4"
                  ></video>
                </div>
                <div style="position: relative; z-index: 2">
                  <!-- <img class="general01" src="~static/general01.png" />
                  <img class="general02" src="~static/general02.png" />
                  <img class="general05" src="~static/general05.png" /> -->
                  <!-- <p v-html="decodeHTML( tzn_gkText[0] && tzn_gkText[0].content)"></p> -->
                  <p style="text-align: left">
                    Tiaozini Wetland in Dongtai, located in the coastal area of
                    Dongtai in the south of the Yellow Sea Ecological Zone,
                    covers an area of 600 square kilometers, among which
                    Tiaozini Scenic Area covers an area of 10.77 square
                    kilometers. Tiaozini Wetland is not only the first World
                    Natural Heritage site in Jiangsu Province and the 14th World
                    Natural Heritage site in China, but also fills the gap in
                    the World Natural Heritage of coastal wetlands in China.
                    <br />
                    Tiaozini was born due to the sea and is famous for birds. It
                    is located in the transition zone between warm temperate
                    zone and subtropical zone, with mild climate and abundant
                    rain. The silty sand mud flats can fix moisture, salt and
                    other nutrients. It is rich in shellfishes and has a vast
                    grassy beach, which is more conducive to the survival of a
                    variety of organisms than the hard rocky coast, attracting
                    countless birds to rest, moult and winter here. It has
                    become the central point along the East Asia-Australasia
                    migration route of migrant birds, which is one of the Top
                    Nine bird migration areas in the world. There are 410
                    species of birds, including 170 species of water birds, with
                    a total number of 1 million. 12 species of birds are listed
                    as the threatened species on the IUCN red list, 21 species
                    listed as birds under first-grade and second-grade state
                    protection in China. Tiaozini is therefore known as "the
                    international airport of birds".
                    <br />
                    Our mother rivers --the Yellow River and the Yangtze River
                    surging eastward, carrying large amounts of sediment,
                    converge and deposit here, forming unique geomorphologic
                    landscape in coastal areas of China such as the "radiated
                    sand ridge groups", "one-line tide", “two halves of water”
                    and "tidal forest", and also showing the beautiful natural
                    scenes such as the "sea bathing under the sun", "sky mirror"
                    "pure starry sky", "groups of ducks under the sunset'" and
                    "Chinese red”.
                    <br />
                    Tiaozini is "green" and "red" because it is the anchor dock
                    of the People's Liberation Army Navy. The Coastal Defense
                    Regiment of the New Fourth Army's Central Jiangsu Military
                    Region entered the sea from here, and used the "red
                    sailboats" selling goods as a cover to build a maritime
                    transport line of counter-Japanese military supplies between
                    northern Jiangsu, southern Shandong and Shanghai. In the
                    film "Army Station 51", there is the prototype of the red
                    sailboats that braved the wind and waves forward. Later, the
                    red sailboats also took part in the famous
                    Crossing-the-Yangtze-River Campaign, becoming an important
                    force in the People's Navy. The "red sailboat" was named so
                    because the sail was dyed to be red. Like the "red boat" on
                    the South Lake, it also has unusual historical value, and is
                    now parked in the Tiaozini harbor for people to pay their
                    respects and recall the past.
                  </p>
                  <!-- <p class="general06">带你走进美丽的 条子泥</p> -->
                </div>
              </div>
            </div>
            <!-- <div
              class="generalImg"
              :style="{ 'background-image': 'url(' + backgroundImg + ')', 'background-size': 'cover', 'background-repeat': 'no-repeat' }"
            ></div>-->
          </div>
          <div
            id="introduce"
            class="introduce"
            style="padding: 120px 80px 0 80px"
          >
            <div class="hr-more">Scenic Spot</div>
            <div style="width: 77%; margin: 0 auto">
              <div class="introduce_tabs">
                <span
                  v-for="(item, index) in tabsList"
                  style="width: 16.67%"
                  :key="item.name"
                  @click="ApiintroduceList(String(index))"
                >
                  <img width="113px" :src="item.src" />
                  <p>{{ item.name }}</p>
                </span>
              </div>
              <div style="margin-top: 80px; padding-left: 100px; display: flex">
                <div
                  style="
                    width: 40%;
                    margin-right: 50px;
                    padding-top: 25px;
                    display: flex;
                    align-items: center;
                  "
                >
                  <!-- <p class="textSpan">{{ introduceList[0].title }}</p>
                    v-html="decodeHTML( introduceList[0] && introduceList[0].content)" -->
                  <p class="introduceText" v-html="content"></p>
                </div>
                <div style="width: 880px; position: relative">
                  <!-- <div class="carouselbg"></div> -->
                  <img
                    style="position: absolute; z-index: 9"
                    src="~static/introducebg01.png"
                    alt=""
                  />
                  <el-carousel
                    height="500px"
                    style="width: 110%; margin-left: 60px; margin-top: 30px"
                  >
                    <el-carousel-item v-for="i in introduceList" :key="i.id">
                      <el-image
                        style="height: 600px"
                        :fit="'cover'"
                        :src="i.thumbnail"
                      ></el-image>
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </div>
            </div>
          </div>
          <div class="greatDeeds" style="padding: 120px 80px 0 80px">
            <div class="hr-more">Pictures</div>
            <div class="imgBox">
              <el-tabs
                v-model="activeName"
                @tab-click="handleClick"
                stretch
                style="width: 100%; padding: 0 30px"
              >
                <el-tab-pane
                  v-for="i in imageTitleList"
                  :key="i.id"
                  :label="i.catalogueName"
                  :name="i.id"
                >
                  <div
                    class="bird-image"
                    v-if="i.id == '402880447ba001ab017ba0096fdb005f'"
                  >
                    <div
                      class="brief"
                      @click="handleGo(item.birdType, item.catalogueId)"
                      v-for="item in pictureList"
                      :key="item.id"
                    >
                      <div
                        class="bird-box"
                        :style="{
                          'background-image': 'url(' + item.picUrl + ')',
                        }"
                      >
                        <!-- <p>
                          {{ item.birdType && item.birdType.split("|")[3] }}
                        </p> -->
                        <img
                          src="~static/scenery/brid-box.png"
                          style="opacity: 0.7"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="scenic-cultural" v-else>
                    <div
                      class="tzn-brief"
                      v-for="item in pictureList"
                      :key="item.id"
                    >
                      <div
                        class="bird-box"
                        :style="{
                          'background-image': 'url(' + item.picUrl + ')',
                        }"
                      >
                        <img
                          src="~static/scenery/brid-box.png"
                          style="opacity: 0.7"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
          <div class="greatDeeds">
            <div style="width: 60%; margin: 0 auto; position: relative">
              <div class="hr-more">Videos</div>
              <div class="movie">
                <div
                  class="video"
                  v-for="(item, index) in videoList"
                  :key="index"
                >
                  <span class="video-box2"></span>
                  <video
                    style="object-fit: fill"
                    controls="controls"
                    :src="item.videoPath"
                    type="video/mp4"
                  ></video>
                </div>
              </div>
            </div>
          </div>
          <div class="greatDeeds">
            <div class="hr-more">Review</div>
            <div>
              <div class="Hrbg2" style="font-size: 16px">
                Focus on Tiaozini, Photography Carnival
              </div>
              <div class="tabs">
                <div
                  @click="tabsListApi(2, '402880447baab07f017baac4d5fc00bb')"
                  :class="activeNum2 ? 'tabsB' : ''"
                >
                  Past work
                </div>
                <div
                  @click="tabsListApi(1, '402880447baab07f017baac4d5fc00bb')"
                  :class="activeNum2 ? '' : 'tabsB'"
                >
                  site tour
                </div>
              </div>
              <div class="imgBox">
                <div
                  v-for="item in tabs01List"
                  :key="item.id"
                  :style="{
                    'background-image': 'url(' + item.picUrl + ')',
                    margin: '40px',
                    'background-repeat': 'no-repeat',
                    'background-position': '50%',
                    'border-radius': '50%',
                    'background-size': 'cover',
                  }"
                >
                  <img
                    style="margin-left: -40px"
                    src="~static/tabsbg01.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="greatDeeds" style="padding: 120px 80px 0 80px">
            <div class="Hrbg2">Flat Mud Play</div>
            <div class="imgBox">
              <div
                v-for="item in tabs02List"
                :key="item.id"
                :style="{
                  'background-image': 'url(' + item.picUrl + ')',
                  margin: '20px',
                  'background-repeat': 'no-repeat',
                  width: '370px',
                  height: '244px',
                  'background-size': 'cover',
                }"
              >
                <img
                  style="margin-left: -10px"
                  src="~static/tabsbg02.png"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div class="greatDeeds" style="padding: 120px 80px 0 80px">
            <div class="Hrbg2">Triathlon</div>
            <div class="imgBox">
              <div
                v-for="item in tabs04List"
                :key="item.img"
                :style="{
                  'background-image': 'url(' + item.picUrl + ')',
                  margin: '50px',
                  'background-repeat': 'no-repeat',
                  width: '348px',
                  height: '348px',
                  'background-size': 'cover',
                }"
              >
                <img
                  style="margin-left: -4px"
                  src="~static/tabsbg04.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div
            id="geography"
            class="geography"
            style="padding: 120px 80px 80px 80px"
          >
            <div class="hr-more" style="font-size: 28px">
              Traffic Information
            </div>
            <div
              style="height: 590px; display: flex; margin: 0 auto; width: 90%"
            >
              <div class="greatDeeds_2">
                <p style="font-weight: bold">
                  Self-driving Tour: <br /><br />
                  Route 1: <br />
                  →Shenyang-Haikou Highway G15 <br />
                  →Drive for 42 km eastwards along the Provincial Highway S352
                  <br />
                  →Tiaozini Scenic Area <br />
                  →Reach G228 National Road on the Linhai Highway <br />
                  →Turn left to the Provincial Highway S352 and drive for 500 m
                  <br />
                  →Tiaozini Scenic Area <br /><br />

                  Bus：<br />
                  Dongtai Bus Terminal To Tiaozini<br />
                  6:10、6:40、7:40、11:40、14:40 <br />
                  Tiaozini To Dongtai Bus Terminal <br />
                  8:40、9:40、10:10、10:40、11:40、<br />
                  12:10、13:10、14:10、14:40、 17:50、<br />
                  Sancang Martyrs'Mausoleum To Tiaozini<br />
                  8:00、9:00、 12:30、13:30、14:30、15:30 <br />
                  Tiaozini To Sancang Martyrs'Mausoleum <br />
                  9:50、10:50、13:30、14:30、14:50、16:50<br />

                  Hotline：0515-85680999 <br />
                  Emergency：0515-85680996 <br />
                  Complaint：0515-85680998 <br />
                </p>
              </div>
              <iframe
                v-if="flag"
                style="width: 100%; height: 100%; margin-left: 20px"
                name="my-iframe"
                id="my-iframe"
                src="https://apis.map.qq.com/uri/v1/marker?marker=coord:32.760766,120.908494;title:条子泥游客中心;&referer=myapp"
                frameborder="0"
                width="100vw"
                height="100vh"
                scrolling="no"
              ></iframe>
            </div>
          </div>
          <div class="greatDeeds">
            <div class="hr-more" style="font-size: 19px">
              <div>Ticket and Service Prices</div>
            </div>
            <div>
              <div class="essentialImg">
                <img style="width: 65%" src="~static/ENessential1.png" alt="" />
              </div>
            </div>
            <div class="essentialImg">
              <img
                style="vertical-align: middle; width: 100%"
                src="~static/ENessential4.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="Lfloat" ref="Lfloat">
          <div>
            <!-- <p>走进条子泥</p> -->
            <!-- <a href="javascript:;" @click="backTop">条子泥印象</a> -->
            <router-link to="/english?name=Introduction"
              >Introduction</router-link
            >
            <router-link to="/english?name=ScenicSpot">Scenic Spot</router-link>
            <router-link to="/english?name=Pictures">Pictures</router-link>
            <router-link to="/english?name=Videos">Videos</router-link>
            <router-link to="/english?name=Review">Review</router-link>
            <router-link to="/english?name=Traffic">Traffic</router-link>
            <router-link to="/english?name=TicketandService"
              >Ticket-Service</router-link
            >
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import * as api from "@/api/test/test";
import { tzn_tplbAPI, tzn_tpxqAPI, tzn_jmspAPI } from "@/api/test/scenery";
export default {
  name: "About",
  data() {
    return {
      list: ``,
      slideshowList: ``,
      // backgroundImg: ``,
      introduceList: {},
      content:
        'The Tiaozini high tide level habitat, with a total area of 48 ha, can provide a 720° panoramic view of water, land and air, so it is used to be called "720". In April 2020, it was built to provide shelter for migratory birds from high tide. As many as 410 species of birds inhabit here. It is praised as "a typical example of migratory bird conservation" by the International Bird Conservation Organization.',
      tabsList: [
        { name: "7 2 0", src: require("static/tabs01.png") },
        { name: "Two Halves of Water", src: require("static/tabs02.png") },
        { name: "One-line Tide", src: require("static/tabs03.png") },
        { name: "Wetland Chinese Red", src: require("static/tabs04.png") },
        { name: "Mirror of the sky", src: require("static/tabs05.png") },
        { name: "Tidal Forest", src: require("static/tabs06.png") },
      ],
      tabs01List: [],
      tabs02List: [],
      tabs04List: [],
      activeNum: 4,
      activeNum2: true,
      tzn_gkText: ``,
      tzn_jtznText: ``,
      greatDeeds_list: ``,
      activeName: "402880447ba001ab017ba0096fdb005f",
      imageTitleList: [
        {
          id: "402880447ba001ab017ba0094ba9005b",
          catalogueName: "Scenery of Tiaozini",
        },
        {
          id: "402880447ba001ab017ba0096fdb005f",
          catalogueName: "Birds of Tiaozini",
        },
        {
          id: "402880447ba001ab017ba009aa340063",
          catalogueName: "Culture of Tiaozini",
        },
      ],
      pictureList: [],
      videoList: [],
      flag: false,
    };
  },
  methods: {
    ApiintroduceList(key) {
      switch (key) {
        case "0":
          api.tzn_sdjs().then((res) => {
            this.introduceList = res;
          });
          this.content =
            'The Tiaozini high tide level habitat, with a total area of 48 ha, can provide a 720° panoramic view of water, land and air, so it is used to be called "720". In April 2020, it was built to provide shelter for migratory birds from high tide. As many as 410 species of birds inhabit here. It is praised as "a typical example of migratory bird conservation" by the International Bird Conservation Organization.';
          break;
        case "1":
          api.tzn_efs().then((res) => {
            this.introduceList = res;
          });
          this.content =
            'There is also the strange wonder of "Two Halves of Water" in the tide-watching area of Tiaozini. There are dozens of variable sandbanks with different sizes and directions in the sea area of Dongtai. Among them, there is a north-south sea area between the Dongsha Sandbank and Xisha Sandbank called the Western Ocean. The Western Ocean is shaped like a trumpet. Every day when the tide rises, the surging tide from the western part of the Yellow Sea enters the northern party of the Western Ocean, while the roaring tide from the northern part of the East China Sea directly hits the southern part of the Western Ocean. The two tide waves meet and collide here, thus forming the extremely spectacular scene of "Two Halves of Water". You can see the "Two Halves of Water" in Tiaozini all year round. In the flood season of July, August and September, if there is the northeaster of level 7 or above, when the tide rises, the two mutually incompatible tide waves from the north and south will enter the U-shaped narrow corridor of the Western Ocean and will quickly form a "tiger head tide" of 1 to 2 meters high. The tide in the front does not retreat, and the tide behind follows. They sometimes are like the Avalokitesvara puts palms together and sometimes are like the fairy spreading flowers. The spectacular scene can last for 10 to 20 minutes. It is interesting to note that when the two tide waves which converge on the surface of the Western Ocean ebb, they still return to the sea between the horizon, following their respective paths they came. ';
          break;
        case "2":
          api.tzn_sdtm().then((res) => {
            this.introduceList = res;
            this.content =
              'Every year at the autumn equinox, the tidal surge of Tiaozini is most spectacular, and you can even see the "one-line tide" when the wind is strong at sea. "One-line tide" is that a white thread slowly appears on from the surface of the mud flat. There is also loud rumbling. As the sound is getting louder and louder, in the distance, there will appear a white line on the surface of the sea rapidly moving forward, like “a white ribbon on the river and a white rainbow from the mud flat”. With the rapid advance of a white wall, the tide will come into sight, with the momentum of ten thousand horses galloping. ';
          });
          break;
        case "3":
          api.tzn_gcth().then((res) => {
            this.introduceList = res;
          });
          this.content =
            'Seepweed, also known as salt wormwood, an annual plant, usually grows in seashore, wasteland, canal bank, beside field and other soil containing salt and alkali, which are the most ideal breeding zones of black-billed gulls. The salt wormwood field is located at the north end of Haijing Road, Tiaozini, covering about 333.3 ha. It is known as the "red carpet of wetland". As a new internet-famous site, it has repeatedly boarded Xinhuanet.com, Xuexi.cn and other media platforms. Salt wormwood is undershrub. It is one of the good sand-binders with strong sand-fixing performance. The shoots and leaves can be used as medicine to relieve cough and asthma and symptoms, and is expectorant and anti-inflammatory. In Mongolian medicine, it is used to treat chronic bronchitis and bronchial asthma.';
          break;
        case "4":
          api.tzn_hqxq().then((res) => {
            this.introduceList = res;
          });
          this.content =
            "The tide has not yet receded, the sea is like a smooth mirror, let the heavens reflect let you and I in the sea of clouds. Pair into a pair of shadows, forming a mirror world.";
          break;
        case "5":
          api.tzn_sdsj().then((res) => {
            this.introduceList = res;
          });
          this.content =
            'The tide leaves traces after it ebbs. The sandbanks are like pictures. The grooves formed by the ebb and flow look like towering trees. They are densely packed in Tiaozini Wetland, hence the name "Tidal Forest".Under the blue sky, the wetland looks wonderful. Thousands of birds fly here, presenting a harmonious scene.';
          break;
        default:
          break;
      }
    },
    //鸟类图片跳转
    handleGo(birdType, catalogueId) {
      this.$router.push({
        path: "/scenerydetails",
        query: {
          birdType: birdType.split("|")[2],
          catalogueId,
        },
      });
    },
    //teb切换
    handleClick(tab, event) {
      console.log(tab, event);
      tzn_tpxqAPI({
        isUse: 1,
        catalogueId: this.activeName,
        size: this.activeName == "402880447ba001ab017ba0096fdb005f" ? 8 : 6,
        page: 0,
      }).then(({ data }) => {
        this.pictureList = data;
      });
    },
    tabsListApi(e = 1, activeId) {
      api
        .layUIPage({
          isUse: "1",
          activeId,
          picType: e,
          size: 6,
          page: 0,
        })
        .then(({ data }) => {
          switch (activeId) {
            case "402880447baab07f017baac4d5fc00bb": // 摄影欢乐会
              this.tabs01List = data;
              e == 1 ? (this.activeNum2 = true) : (this.activeNum2 = false);
              break;
            case "402880447baab07f017baac511bd00c0": //滩玩儿
              this.tabs02List = data;
              break;
            case "402880447baab07f017baac5d7dc00cf": // 铁人三项
              this.tabs04List = data;
              break;
            default:
              break;
          }
        });
    },
    decodeHTML(str) {
      var s = "";
      if (!str || str.length == 0) return "";
      s = str.replace(/&amp;/g, "&");
      s = s.replace(/&lt;/g, "<");
      s = s.replace(/&gt;/g, ">");
      s = s.replace(/&nbsp;/g, " ");
      s = s.replace(/&#39;/g, "'");
      s = s.replace(/&quot;/g, '"');
      return s;
    },
    windowScroll() {
      // 滚动条距离页面顶部的距离
      // 以下写法原生兼容
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop < 430) {
        this.$refs.Lfloat.style.top = 617 - scrollTop + "px";
      } else {
        this.$refs.Lfloat.style.top = "30%";
      }
      if (scrollTop > 7700) {
        this.flag = true;
      }
      console.log(scrollTop, "位置");
    },
    backTop() {
      const that = this;
      let ispeed = Math.floor(that.scrollTop / 5);
      document.documentElement.scrollTop = document.body.scrollTop =
        that.scrollTop + ispeed;
      if (that.scrollTop === 0) {
        clearInterval(timer);
      }
    },
  },
  created() {},
  mounted() {
    //图片目录
    // this.$nextTick(() =>{
    // tzn_tplbAPI().then((res) => {
    //   this.imageTitleList = res;
    // });
    // })

    //精美视频
    tzn_jmspAPI({
      isUse: 1,
      size: 6,
      page: 0,
    }).then((res) => {
      this.videoList = res.data;
    });
    this.handleClick(); //精美图片
    api.tzn_sdjs().then((res) => {
      this.introduceList = res;
    });
    api.slideshow().then((res) => {
      this.slideshowList = res;
    });
    api.tzn_gk().then(({ data }) => {
      this.tzn_gkText = data;
    });
    api.tzn_jtzn().then(({ data }) => {
      this.tzn_jtznText = data;
    });
    api.tzn_zdsj().then(({ data }) => {
      this.greatDeeds_list = data;
    });
    this.tabsListApi(2, "402880447baab07f017baac4d5fc00bb"); // 摄影欢乐会
    this.tabsListApi(0, "402880447baab07f017baac511bd00c0"); //滩玩儿
    this.tabsListApi(0, "402880447baab07f017baac5d7dc00cf"); // 铁人三项
    window.addEventListener("scroll", this.windowScroll);

    // console.log(this.introduceList);
  },
  destroyed() {
    // 离开该页面需要移除这个监听的事件，不然会报错
    window.removeEventListener("scroll", this.windowScroll);
  },
};
</script>
<style scoped>
.essentialImg {
  text-align: center;
}
.tabs {
  display: flex;
  justify-content: center;
}
.tabs > div {
  width: 230px;
  height: 59px;
  line-height: 59px;
  text-align: center;
  margin: 15px;
  font-size: 23px;
  color: #509ab1;
  background: url("~static/tab.png") no-repeat;
}
.tabsB {
  background: url("~static/tabAcitve.png") no-repeat !important;
  background-size: cover !important;
  color: #f6d397 !important;
}
.Hrbg2 {
  width: 440px;
  height: 85px;
  line-height: 85px;
  text-align: center;
  margin: 0 auto;
  font-size: 28px;
  font-family: PangMenZhengDao-Cu;
  font-weight: 400;
  color: #f6d397;
  background: url("~static/Hrbg2.png") no-repeat;
}
.movie {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.image {
  width: 450px;
  height: 250px;
  background-image: url("../../../static/scenery/img-box.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
  line-height: 250px;
  margin: 55px auto;
  letter-spacing: 10px;
  font-size: 42px;
  color: #509ab1;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.28);
}
.video {
  position: relative;
  margin-bottom: 30px;
  width: 31%;
  height: 355px;
}
.video-box2 {
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background-image: url("../../../static/scenery/video-box.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: -2px;
}
.video > video {
  width: 98%;
  height: 100%;
}
/deep/ .el-tabs__item {
  background-image: url("~static/scenery/bgbox.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #f6d397;
  margin: 0 15px;
  padding: 0;
  height: 60px;
  line-height: 60px;
  font-size: 28px;
}
/deep/ .el-tabs__item.is-active {
  background-image: url("~static/scenery/bgbox-select.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #509ab1;
}
/deep/ .el-tabs__active-bar {
  display: none;
}
/deep/ .el-tabs__nav-wrap::after {
  display: none;
}
/deep/ .el-tabs__nav.is-stretch {
  min-width: inherit;
  width: 100%;
  box-sizing: border-box;
  padding: 0 10%;
  margin-bottom: 52px;
}
.brief {
  margin: 8px;
  width: 23%;
  cursor: pointer;
}
.tzn-brief {
  margin: 8px;
  width: 30%;
  cursor: pointer;
}
.bird-box {
  position: relative;
  background-repeat: no-repeat;
  background-size: 98% 98%;
}
.bird-box > p {
  position: absolute;
  top: 21%;
  left: 10%;
  width: 30px;
  font-size: 28px;
  font-family: "tzn-font";
  color: #fff;
  line-height: 40px;
}
.bird-box > img {
  display: block;
  width: 100%;
  height: auto;
}
.bird-image,
.scenic-cultural {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.video-box {
  width: 783px;
  height: 700px;
  background-image: url(~static/videoBg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: -116px;
  top: -29%;
}
>>> .el-breadcrumb {
  line-height: 92px;
  font-size: 24px;
}
>>> .el-breadcrumb__inner a:hover,
.el-breadcrumb__inner.is-link:hover {
  color: #509ab1 !important;
}
.top-title {
  background-size: cover;
  background-image: url(/static/img/title.ab49d158.png);
  background-repeat: no-repeat;
  letter-spacing: 5px;
  width: 1200px;
  margin: 0 auto;
  text-align: left;
  line-height: 92px;
  font-size: 24px;
}
.home-icon {
  width: 20px;
  height: 20px;
  margin: 0 16px 0 53px;
}
.top-title > span:hover {
  cursor: pointer;
  color: #509ab1;
}
.Aboutbg {
  position: absolute;
  z-index: -1;
}
.introduce_tabs,
.scenic {
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.introduce_tabs:before,
.introduce_tabs:after,
.scenic:before,
.scenic:after {
  content: "";
  display: block;
}
.introduce_tabs p,
.textSpan {
  font-size: 25px;
  font-family: PangMenZhengDao-Cu;
  font-weight: bold;
  color: #509ab1;
}
.debris {
  width: 100%;
  margin-top: -157px;
  z-index: 8;
  background: transparent;
  position: relative;
}
.active {
  background-color: #509ab1;
}
.greatDeeds_r {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.greatDeeds_r h3 {
  width: 55px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #509ab1;
  line-height: 41px;
  white-space: nowrap;
}
.greatDeeds_r h5 {
  width: 357px;
  height: 18px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
}
.greatDeeds_r > div {
  /* position: absolute; */
  margin: 40px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.greatDeeds_bzt {
  height: 100%;
  width: 2px;
  margin: 0 auto;
  border: none;
  background: darkgray;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.greatDeeds_bzt > div {
  height: 136px;
  margin: 40px 0;
}
.greatDeeds_bzt > div > h2 {
  right: -19px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #509ab1;
  border-radius: 25px;
  text-align: center;
}
img[class^="general"] {
  position: absolute;
  z-index: -99;
}
.generalbg {
  left: -45%;
  top: -27%;
}
.general01 {
  right: -27%;
}
.general02 {
  right: -36%;
  top: 65%;
}
.general03 {
  height: 140px;
  left: -18%;
  top: -6%;
}
.general04 {
  bottom: -25%;
  left: 88%;
  z-index: -1;
}
.general05 {
  left: -30%;
}
.general06 {
  writing-mode: tb-rl;
  position: absolute;
  right: -51%;
  top: 4%;
  font-size: 19px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #363a31;
  line-height: 24px;
}
.greatDeeds {
  /* background: url("~static/greatDeedsimg.png") no-repeat center;
  background-size: cover;
  height: 1000px; */
  /* transform: scale(0.93, 1); */
}
.Hcontent {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 160px;
  background: url("~static/img3bg.png") no-repeat 100% 100%;
  background-size: cover;
  height: 600px;
}
.content-text {
  width: 142px;
  height: 231px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: url("~static/img13.png") no-repeat 100% 100%;
}
.content-text > p {
  width: 30px;
  font-size: 21px;
  font-weight: bold;
  line-height: 29px;
  color: #317981;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* 静态资源 */
.content-grid {
  width: 220px;
  height: 320px;
  z-index: 12;
  position: relative;
}
.Lfloat {
  /* display: none; */
  position: fixed;
  width: 352px;
  height: 527px;
  left: -2%;
  top: 617px;
  z-index: 99;
  background: url("~static/flotbg2.png") no-repeat 100% 100%;
  /* transform: scale(0.75); */
}
.Lfloat > div {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 38%;
}
.Lfloat > div > p {
  text-align: center;
  height: 23px;
  font-size: 24px;
  font-family: PangMenZhengDao-Cu;
  font-weight: 400;
  color: #5098af;
  line-height: 11px;
}
ul {
  list-style: none;
}
.Lfloat a {
  display: block;
  color: #5098af;
  line-height: 27px;
  margin: 13px 0px -3px 0px;
  text-align: center;
  width: 128px;
  height: 29px;
  background: url("~static/flotInbg.png") no-repeat 100% 100%;
}
.introduceText {
  white-space: pre-wrap;
  overflow-y: auto;
  margin-top: 10px;
}
.introduce {
  height: 1100px;
  /* background: url("~static/introducebg.png") no-repeat; */
  background-size: cover;
}
.el-tabs__content > div {
  display: flex;
}
.greatDeeds_2 {
  margin-left: 10%;
  /* width: 45%; */
  height: 100%;
  overflow-y: auto;
}
.greatDeeds_2 > div {
  padding-left: 40px;
  background-position-y: 50%;
}
.icon1 {
  background: url("~static/msg.png") no-repeat;
}
.icon2 {
  background: url("~static/site.png") no-repeat;
}
.icon3 {
  background: url("~static/time.png") no-repeat;
}
.icon4 {
  background: url("~static/icon4.png") no-repeat;
}

>>> .el-tabs__content {
  /* height: 300px; */
}
.hr-more {
  white-space: nowrap;
  letter-spacing: 3px;
  line-height: 250px;
  z-index: 9;
  width: 452px;
  height: 250px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: -19px;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  background: url("~static/Hrbg.png");
  font-size: 35px;
  font-family: PangMenZhengDao-Cu;
  font-weight: 400;
  color: #509ab1;
  text-shadow: ;
}
.hr-more img {
  margin: 0 6px;
}
.hr-more span {
  width: 119px;
  height: 9px;
  font-size: 12px;
  font-family: Arial;
  font-weight: 400;
  color: #b3b3b3;
  line-height: 24px;
}
.about {
  position: relative;
}
.general {
  display: flex;
  /* box-shadow: 0px -7px 18px 0px rgb(0 0 0 / 10%); */
  padding-left: 20px;
  margin-bottom: 50px;
  /* background: url("~static/generalbg.png") no-repeat -6% -49%; */
}
.general p {
  text-indent: 28px;
  text-align-last: start;
  width: 430px;
  height: 400px;
  line-height: 2.5;
  overflow-y: auto;
  /* writing-mode: tb-rl; */
  /* letter-spacing: 12px; */
}
.generalImg {
  flex: 1;
}
.imgBox {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  transform: scale(0.8);
  width: 1400px;
  margin: 0 auto;
}
.el-main {
  padding: 0;
  overflow-x: hidden;
}
</style>
